import images from './images';

export const URL = 'https://app.joblik.ma';
export const URL_PORTAIL = 'https://portail.joblik.ma/';

// export const URL = 'http://127.0.0.1:8000';
export const URL_API = `${URL}/api`;
export { images };

// Les liens de navigation
export const navLinks = [
    { href: "/entreprises", label: "Entreprises" },
    { href: "/offres-emploi", label: "Offres d'emploi" },
    { href: "/actualites", label: "Actualités" },
    { href: "/login", label: "Login" },
    { href: "/register", label: "Register" },
];

// Les différentes URLs de l'API
export const API_URLS = {
    LOGIN: `${URL_API}/login`,
    LOGOUT: `${URL_API}/logout`,
    SIGNUP: `${URL_API}/signup`,
    PROFILE: `${URL_API}/user-profile`,
    UPDATE_CV: `${URL_API}/profile/update-resume`,
};


// export const ValuesData = [
//     {
//         id: 1,
//         logo: Image1,
//         title: "Accounting",
//     },
//     {
//         id: 2,
//         logo: Image2,
//         title: "Development",
//     },
//     {
//         id: 3,
//         logo: Image3,
//         title: "Technology",
//     }
//     ,
//     {
//         id: 4,
//         logo: Image4,
//         title: "Media",
//     },
//     {
//         id: 5,
//         logo: Image5,
//         title: "Medical",
//     }
//     ,
//     {
//         id: 6,
//         logo: Image6,
//         title: "Goverment",
//     }
// ];
